import React, { lazy, Suspense } from 'react'
import HomeSkeleton from './HomeSkeleton'
const Home = lazy(() => import("./Home"));

const HomePage = () => {
    return (
        <Suspense fallback={<HomeSkeleton></HomeSkeleton>}>
            <Home/>
        </Suspense>
    )
}

export default HomePage