//styles
import "./HeaderMobile.scss"


//images
import logo from "../../../img/logo_codeb.png"
import facebook from "../../../img/icons/facebook.svg"
import likendin from "../../../img/icons/likendin.svg"
import instagram from "../../../img/icons/instagram.png"
import mexico from "../../../img/icons/mexico.svg"
import us from "../../../img/icons/us.svg"
import leftArrow from '../../../assets/image/left-arrow.png'

//react & primereact
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useEffect, useState } from "react"
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import { Skeleton } from "primereact/skeleton"

const HeaderMobile = ({ skeleton }) => {

    const options = [  //Header options
        { title: "Inicio", id: "home" },
        { title: "Nosotros", id: "us" },
        { title: "Servicios", id: "services" },
        { title: "Portafolio", id: "projects" },
        { title: "Equipo", id: "team" },
        { title: "Contacto", id: "contact" },
    ]
    const [visible, setVisible] = useState(false);

    // function to go to a selected id of the page
    const scrollToMyRef = (id) => {
        document.getElementById(`${id}`)?.scrollIntoView({ behavior: "smooth" });
        setVisible(false)

    };

    const navigate = useNavigate();
    const [routeActual, setRouteActual] = useState();
    let location = useLocation()
    
    useEffect(() => {
        setRouteActual(location.pathname);
    }, [location])

    return (

        !skeleton ? (
            <div className='HeaderMobile'>
                <div className="btnMain">
                    {
                        routeActual !== "/" ?
                            "" :
                            <>
                                <Button icon="pi pi-bars" aria-label="menu" onClick={() => setVisible(true)} />
                            </>
                    }
                    <div className="name">
                        {routeActual !== "/" &&
                            <NavLink to={"/"}>
                                <img src={leftArrow} alt="" className="left_arrow"/>
                            </NavLink>
                        }
                        <h2>Code<span>Builders</span></h2>
                    </div>
                    <Sidebar visible={visible} position="left" onHide={() => setVisible(false)}>
                        <div className="options">
                            <div className="logo">
                                <img alt="codebuilders" name="logo" src={logo} />
                            </div>
                            {/* Iteration of Header options */}
                            <div className="listOptions">
                                {options.map((e, index) => {
                                    return (
                                        <div onClick={() => { e.id === "team" ? navigate("/about") : scrollToMyRef(e.id) }} style={{ width: '100%', height: '30px', display: 'grid', borderBottom: "1px solid #2CD8FA", margin: "10px 0" }}>
                                            <h3 style={{ marginLeft: "15px" }}>{e.title}</h3>
                                        </div>
                                    )
                                })}
                                <div className="ChagueIdiom">
                                    <div>
                                        <Link to={"https://businesscodebuilders.com/"}>
                                            <img src={mexico} width={"30"} alt="" />
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to={"https://en.businesscodebuilders.com/"}>
                                            <img src={us} width={"30"} alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="iconsContainer">
                            <Link aria-label="linkedin" to="https://www.linkedin.com/in/code-builders-2023cdbl" target="_blank">
                                <img alt="" src={likendin} />
                            </Link>
                            <Link aria-label="facebook" to="https://www.facebook.com/profile.php?id=100091074854163" target="_blank">
                                <img alt="" src={facebook} />
                            </Link>
                            <Link aria-label="instagram" to="https://www.instagram.com/codebuilders2023/" target="_blank">
                                <img alt="" src={instagram} />
                            </Link>
                        </div>
                    </Sidebar>
                    <div></div>
                </div>
            </div>
        ) : (
            <div className='HeaderMobile'>
                <div className="btnMain">
                    <Skeleton width='35px' height="35px" borderRadius="50%" />
                    <div className="name">
                        <Skeleton width='170px' height="40px" />
                    </div>
                    <div></div>
                </div>
            </div>
        )
    )
}

export default HeaderMobile