import React, {useState } from "react";
import './Servises.scss'

import { Skeleton } from 'primereact/skeleton';
const ServicesSkeleton = ({ id }) => {

    // escuchador para el ancho de la pantalla
    const [widthScreen] = useState(window.innerWidth);

    return (
        <>

            <div className="Services" id={id}>
                <div className="title" style={{ display: "flex", justifyContent: "center" }}><Skeleton width="60%" height="50px"></Skeleton></div>
                <div className="parentServices">
                    <div className="colServices1">
                        <div className="container-rigth">
                            <div className="titleLow" style={{ color: "#2FE2FC" }}><Skeleton width="60%" height="50px"></Skeleton></div>
                            <div className="text"><Skeleton width="100%" height="200px"></Skeleton></div>
                            <div className="text" style={{ marginTop: "50px" }}><Skeleton width="100%" height="150px"></Skeleton></div>
                        </div>
                    </div>
                    <div className="colServices2">
                        <Skeleton width="90%" height="100%"></Skeleton>
                    </div>
                </div>
                {/* Carrusel */}
                <div className="carrouselSection">
                    <div className="contentCarrouselSection">
                        <div className="titleCarrousel" style={{ display: "flex", justifyContent: "center" }} ><Skeleton width="60%" height="50px"></Skeleton></div>
                        <div className="text center" style={{ display: "flex", justifyContent: "center" }}><Skeleton width="80%" height="20px"></Skeleton></div>
                        <div className="carrouselPosition">
                            <div className="containerSkeletonCarrouselContainer">
                                <div className="containerSkeletonCarrousel">
                                    {
                                        Array.from(widthScreen > 1870 ? { length: 5 } : widthScreen > 1600 ? { length: 4 } : widthScreen > 1350 ? { length: 3 } : widthScreen > 1023 ? { length: 3 } : { length: 1 }).map((item) => {
                                            return (
                                                <>
                                                    <Skeleton className="skeletonCarrousel"></Skeleton>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="graphicDesign">
                    <div className="titleLow" style={{ display: "flex", justifyContent: "center", marginTop: "80px" }}><Skeleton width="35%" height="50px"></Skeleton></div>
                    <div className="text" style={{ display: "flex", justifyContent: "center" }}><Skeleton width="70%" height="20px"></Skeleton></div>
                    <div className="designImagesSection">
                        <div className="designImagesPosition">
                            <Skeleton width="300px" height="300px"></Skeleton>
                            <Skeleton width="300px" height="300px"></Skeleton>
                            <Skeleton width="300px" height="300px"></Skeleton>

                        </div>
                    </div>
                </div>
                <div className="container-button">
                    <Skeleton width="320px" height="60px"></Skeleton>
                </div>
            </div>

        </>
    )
}
export default ServicesSkeleton