import './TextArea2.scss'

import { Skeleton } from 'primereact/skeleton'

export default function TextArea2({
    name, 
    value, 
    setValue, 
    nameTitle, 
    placeholder, 
    required,
    id,
    onChange,
    className,
    disabled,
    width,
    skeleton,
    classNameTA,
    height,
    style,
}){  

    return (!skeleton ?
        <div className={`w-100 ${disabled && "disabledTextArea"} ${className}`} style={{width: width, ...style}}>
            <div className='d-flex justify-content-between'>
                {/* <div className='textareaLabel'>{nameTitle}</div> */}
                {/* {
                    !required ?
                    <div className='textareaRequired' id={id+"obligatory"}>Opcional</div>
                    :
                    <div className='textareaRequired' id={id+"obligatory"}>Obligatorio</div>
                } */}
            </div>

            <textarea 
                className={`w-100 containTextArea ${classNameTA}`} 
                name={name} 
                value={value} 
                onChange={onChange} 
                placeholder={placeholder}
                id={id}
                disabled={disabled}
                style={{height:height}}
                defaultValue=""
            ></textarea>
        </div>

        : <Skeleton width='100%' height='185px' />
    )
}