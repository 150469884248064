import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainPage from "../../pages/MainPage/MainPage";
import PricePackages from "../../pages/PricePackages/PricePackages";
import About from "../../pages/About/About";

const MainRouter = () => {
    return (
        <>
            <Router>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={<Suspense fallback={<>Skeleton</>}>
                            <MainPage />
                        </Suspense>}
                    />
                    <Route
                        exact
                        path="price-packages"
                        element={<Suspense fallback={<>Skeleton</>}>
                            <PricePackages></PricePackages>
                        </Suspense>}
                    />
                    <Route
                        exact
                        path="/about"
                        element={<Suspense fallback={<>Skeleton</>}>
                            <About />
                        </Suspense>}
                    />
                </Routes>
            </Router>
        </>
    )
}
export default MainRouter

