import "./About.scss"
import Header from '../../components/molecules/Header/Header'
import HeaderMobile from '../../components/molecules/HeaderMobile/HeaderMobile'
import joe from "../../img/profile.jpeg"
import diego from "../../img/diego.png"
import jorge from "../../img/jorge.png"
import meet from "../../img/meet.jpeg"
import congreso from "../../img/congreso.jpeg"
import { Link } from "react-router-dom"
import Footer from "../../components/molecules/Footer/Footer"

const About = () => {
    const screen = window.screen.width  // horizontal screen resolution 
    const data_us = [
        { name: "Jorge Antonio Fuerte Diaz", email: "jorgeantoniofd@outlook.es", image: jorge, url: "https://jorgeantoniofd.businesscodebuilders.com/" },
        { name: "Diego Alberto Venegas Hernández", email: "diego.ven80@gmail.com", image: diego, url: "https://diegovenegas.businesscodebuilders.com/" },
        { name: "Joel Arián Trincado Ventura", email: "joeltrincadov@gmail.com", image: joe, url: "/about" },
    ]
    return (
        <div className="cAbout">
            {screen > 768 ? <Header /> : <HeaderMobile />}
            <div className='About'>
                <h1> Somos una empresa especializada en el desarrollo de sitios web y
                    soluciones digitales.</h1>
                <div className="containerCard">
                    {data_us.map((e, index) => {
                        return (
                            <div className="cardDevs" key={index}>
                                <Link to={e.url} target="_blank" rel="noreferrer">
                                    <div className="imgCard">
                                        <img alt="" src={e.image} />
                                    </div>
                                </Link>
                                <div className="info">
                                    <div className="text">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-star" width="25" height="25" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                            <path d="M6 21v-2a4 4 0 0 1 4 -4h.5" />
                                            <path d="M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" />
                                        </svg>
                                        <p>{e.name}</p>
                                    </div>
                                    <div className="text">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-prompt" width="25" height="25" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M5 7l5 5l-5 5" />
                                            <path d="M13 17l6 0" />
                                        </svg>
                                        <p>Desarrollador Web</p>
                                    </div>
                                    <div className="text">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail-star" width="25" height="25" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4.5" />
                                            <path d="M3 7l9 6l9 -6" />
                                            <path d="M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" />
                                        </svg>
                                        <p>{e.email}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <h1>
                    Nos dedicamos a ofrecer servicios de alta calidad para
                    ayudar a las empresas a establecer su presencia en línea de manera efectiva y
                    exitosa.
                </h1>
                <div className="elements">
                    <img alt="" src={congreso} />
                    <div className="elements_inside">
                        <h3>
                            La satisfacción generada en nuestros clientes nos ha brindado
                            la oportunidad de formar parte de destacados eventos donde se presentan
                            sus proyectos.
                        </h3>
                        <a aria-label="galeria" href={'https://www.facebook.com/profile.php?id=100091074854163'} target="_blank" rel="noreferrer">
                            <button className="btnServices">
                                Ver más imágenes
                            </button>
                        </a>
                    </div>
                </div>
                <h1>
                    Nuestra capacidad de adaptación a las últimas tendencias y avances tecnológicos
                    nos permite ofrecer soluciones innovadoras y a la vanguardia.
                </h1>
                <div className="elements seconds_element">
                    <div className="elements_inside">
                        <h3>
                            Brindamos opciones de servicios y planes que se adaptan al presupuesto de
                            nuestros clientes, con el objetivo de impulsar a las pequeñas empresas en el
                            ámbito digital.
                        </h3>
                        <a aria-label="valoraciones" href={'https://www.facebook.com/profile.php?id=100091074854163'} target="_blank" rel="noreferrer">
                            <button className="btnServices">
                                Ver valoraciones
                            </button>
                        </a>
                    </div>
                    <img alt="" src={meet} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About