//styles
import "./Header.scss"

//images
import logo from "../../../assets/image/code_builders-05.png"
import facebook from "../../../img/icons/facebook.svg"
import likendin from "../../../img/icons/likendin.svg"
import instagram from "../../../img/icons/instagram.png"
import mexico from "../../../img/icons/mexico.svg"
import us from "../../../img/icons/us.svg"
import { useEffect, useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import { Skeleton } from "primereact/skeleton"

const Header = ({ skeleton }) => {
    const [setInd] = useState('home')
    const [routeActual, setRouteActual] = useState(null);
    let location = useLocation()
    const options = [  //Header options
        { title: "Inicio", id: "home" },
        { title: "Nosotros", id: "us" },
        { title: "Servicios", id: "services" },
        { title: "Portafolio", id: "projects" },
        // { title: "Equipo", id: "team" },
        { title: "Contacto", id: "contact" },
    ]


    useEffect(() => {
        setRouteActual(location.pathname);
    }, [location])



    // function to go to a selected id of the page
    const scrollToMyRef = (id) => {
        document.getElementById(`${id}`)?.scrollIntoView({ behavior: "smooth" });
        setInd(id)
    };




    return (
        !skeleton ? (
            <div className='Header'>
                <div className="container">
                    <div className="ChagueIdiom">
                        <div>
                            <Link to={"https://businesscodebuilders.com/"}>
                                <img src={mexico} width={"30"} alt="" />
                            </Link>
                        </div>
                        <div>
                            <Link to={"https://en.businesscodebuilders.com/"}>
                                <img src={us} width={"30"} alt="" />
                            </Link>
                        </div>
                    </div>
                    <img alt="codebuilders" className="logo" src={logo} onClick={() => scrollToMyRef("home")} />
                    <div className="options">
                        {routeActual === "/" ? (
                            // interaccion
                            options.map((e, index) => (
                                <h3 key={index} onClick={() => scrollToMyRef(e.id)}>{e.title}</h3>
                            ))
                        ) : (
                            <NavLink to={"/"} className={"navLink-l"}>
                                <h3>Inicio</h3>
                            </NavLink>
                        )}
                    </div>
                    <div className="icons">
                        <Link aria-label="linkedin" to="https://www.linkedin.com/in/code-builders-2023cdbl" target="_blank">
                            <img alt="" src={likendin} />
                        </Link>
                        <Link aria-label="facebook" to="https://www.facebook.com/profile.php?id=100091074854163" target="_blank">
                            <img alt="" src={facebook} />
                        </Link>
                        <Link aria-label="instagram" to="https://www.instagram.com/codebuilders2023/" target="_blank">
                            <img alt="" src={instagram} />
                        </Link>
                    </div>
                </div>
            </div>
        ) : (
            <div className='Header'>
                <div className="container">
                    <Skeleton width='150px' height="40px" />
                    <div className="options">

                        {/* Iteration of Header options */}
                        {options.map((e, index) => {
                            return (
                                <Skeleton width='70px' height="25px" />
                            )
                        })}
                    </div>
                    <div className="icons">
                        <Skeleton width='25px' height="25px" borderRadius="50%" />
                        <Skeleton width='25px' height="25px" borderRadius="50%" />
                        <Skeleton width='25px' height="25px" borderRadius="50%" />
                    </div>
                </div>
            </div>)
    )
}

export default Header