import React from "react";
import CardPrice from "../../components/molecules/CardPrice/CardPrice";
import './PricePackages.scss'
import background_gray_wave from '../../img/background_gray_wave.jpg'
import arrow from '../../img/icons/arrowStrong.svg'
import logo from '../../img/icons/logoOnly.svg'
import { NavLink } from "react-router-dom";
const PricePackages = () => {
    const cardInfo = [
        {
            type: "ESTÁNDAR",
            footer: "COMPRA YA",
            title: "Pagina Web Informativa",
            color: "linear-gradient(158deg, rgba(255,108,122,1) 15%, rgba(254,62,201,1) 80%)",
            price: "$4,500",
            beneficios: [
                "Este paquete es ideal para tener una presencia rapida, eficiente, y eficaz. Diseño Web responsivo se adapta y/o es compatible con smartphones y tablets a un costo accesible.",
                "2 secciones de informacion",
                "Incluye Certificado SSL.",
                "Hasta 10 imágenes de uso libre o proporcionadas por el cliente",
                "1 cuenta de correo electrónico con capacidad de 250MB.",
                "1 formulario de Contacto Básico",
                "Asesoría para la selección del dominio",
                "Dominio Gratis por un año (.com)",
                "Hosting (Hospedaje) Gratis por un año",
                "Optimización SEO",
                "Código optimizado",
                "Enlace a redes sociales",
            ]
        },
        {
            type: "ESPECIAL",
            footer: "COMPRA YA",
            price: "$6,500",
            title: "Pagina Web Emprendedor",
            color: "linear-gradient(158deg, rgba(255,193,56,1) 15%, rgba(255,127,28,1) 80%)",
            beneficios: [
                "Ideal para muestrar sus productos o servicios de una manera rápida y eficiente, Diseño Web responsivo se adapta y/o es compatible con smartphones y tablets.",
                "4 secciones de informacion",
                "Certificado de seguridad SSL.",
                "Hasta 15 imágenes de uso libre o proporcionadas por el cliente",
                "2 cuentas de correo electrónico con capacidad de 250MB.",
                "1 formulario de Contacto hasta con 6 campos",
                "Mapa de Google Maps con dirección a su negocio",
                "Asesoría para la selección del dominio",
                "Optimización SEO",
                "Código optimizado",
                "Dominio Gratis por un año (.com)",
                "Hosting (Hospedaje) Gratis por un año",
                "Enlace a redes sociales",
            ]
        },
        {
            type: "EMPRESARIAL",
            footer: "COMPRA YA",
            price: "$10,000",
            title: "Landing page Informativa administrable",
            color: "linear-gradient(158deg, rgba(40,199,247,1) 15%, rgba(21,132,235,1) 80%)",
            beneficios: [
                "Ideal para muestrar sus productos o servicios de una manera rápida y eficiente, Diseño Web responsivo se adapta y/o es compatible con smartphones y tablets.",
                "4 secciones de informacion",
                "Certificado de seguridad SSL.",
                "Hasta 15 imágenes de uso libre o proporcionadas por el cliente",
                "2 cuentas de correo electrónico con capacidad de 250MB.",
                "1 formulario de Contacto hasta con 6 campos",
                "Mapa de Google Maps con dirección a su negocio",
                "Asesoría para la selección del dominio",
                "Optimización SEO",
                "Código optimizado",
                "Dominio Gratis por un año (.com)",
                "Hosting (Hospedaje) Gratis por un año",
                "Enlace a redes sociales",
            ]
        },
        {
            type: "PROFESIONAL",
            footer: "COMPRA YA",
            price: "$15,000",
            title: "Gestión de la relación con el cliente (CRM)",
            color: "linear-gradient(158deg, rgba(110,247,40,1) 15%, rgba(21,235,100,1) 80%)",
            beneficios: [
                "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.",
                "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.",
                "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.",
                "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.",
                "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.",
                "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.",
            ]
        },

    ]
    return (
        <>
            <div className="PricePackages">
                <img alt="" src={background_gray_wave} className="imgCard" />
                <div className="headerPackages">
                    <div className="parentheaderPackages">
                        <div className="colHeader1">
                            <NavLink to={"/"}>
                                <div className="back"><img alt="" src={arrow} /></div>
                            </NavLink>
                        </div>
                        <div className="colHeader2">

                        </div>
                        <div className="colHeader3">
                            <img src={logo} alt="" className='logoOnly' />
                        </div>
                    </div>
                </div>
                <div className="titleHeader">SELECCIONA EL MEJOR PAQUETE</div>
                <div className="contentCards">
                    {
                        cardInfo.map((item) => {
                            return (
                                <>
                                    <CardPrice type={item.type} footer={item.footer} color={item.color} beneficios={item.beneficios} title={item.title} price={item.price}></CardPrice>
                                </>
                            )

                        })
                    }
                </div>
            </div>
        </>
    )
}
export default PricePackages