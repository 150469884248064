import React from "react";
import './Footer.scss'
import logo_footer from '../../../img/logo_footer.png'
import facebook from '../../../img/icons/facebook.svg'
import likendin from '../../../img/icons/likendin.svg'
import instagram from '../../../img/icons/instagram.png'
import { Link } from "react-router-dom";

const Footer = () => {
    return (<>
        <div className="Footer">
            <div className="parentFooter">
                <div className="colFooter1"></div>
                <div className="colFooter2">
                    <img src={logo_footer} className='logo_footer' alt="" />
                    <div className="container-icons-in">
                        <a aria-label="linkedin" href="www.linkedin.com/in/code-builders-2023cdbl" target="_blank">
                            <img src={likendin} alt="" />
                        </a>
                        <a aria-label="facebook" href="https://www.facebook.com/profile.php?id=100091074854163" target="_blank">
                            <img src={facebook} alt="" />
                        </a>
                        <a aria-label="instagram" href="https://www.instagram.com/codebuilders2023/" target="_blank">
                            <img src={instagram} alt="" />
                        </a>
                    </div>
                </div>
                <div className="colFooter3">
                    <div className="titleFooter">Servicios</div>
                    {/* <div className="planeTextFooter">Misión</div> */}
                    <Link aria-label="acerca de" to="/about">
                        <div className="planeTextFooter">Acerca de nosotros</div>
                    </Link>
                    {/* <div className="planeTextFooter">¿Por qué somos los mejores?</div> */}
                    {/* <div className="planeTextFooter">Equipo</div> */}
                </div>
                <div className="colFooter4">
                    <div className="titleFooter">Compañía</div>
                    <div className="planeTextFooter">Servicios</div>
                    {/* <div className="planeTextFooter">Testimoniales</div> */}
                </div>
                <div className="colFooter5">
                    <div className="titleFooter">Recursos</div>
                    {/* <div className="planeTextFooter">Contacto</div> */}
                    {/* <div className="planeTextFooter">Blog</div> */}
                    <div className="planeTextFooter">F.A.Q.</div>
                </div>
                <div className="colFooter6"></div>
            </div>
            <div className="footerfinal"></div>
        </div>
    </>)
}
export default Footer 