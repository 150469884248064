import React from "react";
import './CardPrice.scss'



const CardPrice = ({type="ESTANDAR",footer="COMPRA YA",color="linear-gradient(158deg, rgba(255,108,122,1) 15%, rgba(254,62,201,1) 80%)",beneficios=[],title="title",price="00,00"}) => {
   
    return (
        <>
            <div className="CardPrice">
                <div className="cardContent">
                    <div className="dobles b-l">.</div>
                    <div className="cardTitle"  style={{background:color}}>{type}</div>
                    <div className="dobles b-r">.</div>
                </div>
                    <div className="body-content">
                        <div className="titleCard">{title}</div>
                        <div className="priceCard">{price}</div>
                    {
                        beneficios.map((item)=>
                        {
                            return(
                                <>
                                    <div className="adventaje-container">
                                        <div className="circle" style={{background:color}}></div>
                                        <div className="textCard">{item}</div>
                                    </div>
                                </>
                            )
                        })
                    }
                        
                    </div>
                <div className="footerCardContainer">
                    <div className="footerCard" style={{background:color}}>{footer}</div>
                </div>
            </div>
        </>
    )
}
export default CardPrice