import React, { useEffect, useRef, useState } from "react";
import './TeamAndContactUs.scss'
import background_wave_blue from '../../../img/background_wave_blue.jpg'
import grafico_necesitas_tecnologia from '../../../img/grafico_necesitas_tecnologia.png'
import grafico_contactanos from '../../../img/grafico_contactanos.png'
import InputText from "../../atoms/InputText/InputText";
import TextArea2 from "../../atoms/TextArea2/TextArea2";
import { ColorValidation, SubmitValidation, UpdateValue } from "../../../utiles/Validations";
import emailjs from 'emailjs-com';
import { Toast } from 'primereact/toast';
const TeamAndContactUs = () => {
    const toast = useRef(null);
    const [inputList, setInputList] = useState({
        name: { value: null, validationType: "empty" },
        email: { value: null, validationType: "email" },
        phone: { value: null, validationType: "empty" },
        message: { value: null, validationType: "empty" },
    })


    useEffect(() => {
        for (const propertyName in inputList) {
            if (document.getElementById(propertyName)) {
                ColorValidation(propertyName, inputList);
                if (propertyName === "email") {
                    ColorValidation(propertyName, inputList, "email");
                }
            }

        }
    }, [inputList])

    // function to reset form values
    function handleReset(e) {
        const resetForm = {
            name: { value: null, validationType: "empty" },
            email: { value: null, validationType: "email" },
            phone: { value: null, validationType: "empty" },
            message: { value: null, validationType: "empty" },
        }
        setInputList(resetForm)
        e.target.reset()
    }

    // function to submit form values
    const sendEmail = (e) => {
        e.preventDefault()
        if (SubmitValidation(inputList, setInputList)) {
            
            const serviceID = `${process.env.REACT_APP_SERVICEID}`; //protected variables in the .env file
            const templateID = `${process.env.REACT_APP_TEMPLATEID}`; //protected variables in the .env file
            emailjs.init(`${process.env.REACT_APP_KEY}`) //protected variables in the .env file
            emailjs.sendForm(serviceID, templateID, e.target)
                .then((result) => {
                    handleReset(e)
                    toast.current.show({ severity: 'info', summary: 'Listo', detail: 'Su información se envió con éxito' });
                }, (error) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: `Lo sentimos: ${error.text}` });
                });
        }
    }
    return (
        <>
            <div className="TeamAndContactUs">
                {/* has una invercion inteligente */}
                <div className="smartInversion" id="services">
                    <img src={background_wave_blue} className={"background"} alt="" />
                    <div className="parent-smartInversion">
                        <div className="col-smartInversion1">

                            <div className="container-info-smartInversion">
                                <div className="text-content">
                                    TU NEGOCIO NECESITA DE LA TECNOLOGÍA
                                </div>
                                <button className="buttonInverssion">HAZ UNA INVERSIÓN INTELIGENTE</button>
                            </div>
                        </div>
                        <div className="col-smartInversion2">
                            <img src={grafico_necesitas_tecnologia} alt="" className='pc-smartInverssion' />
                        </div>
                    </div>
                </div>
            </div>
            {/* constacto*/}
            <div className="contactUs" id="contact">
                <div className="parentcontactUs">
                    <div className="colparentcontactUs1">
                        <img src={grafico_contactanos} alt="" className="img-contactus" />
                    </div>
                    <div className="colparentcontactUs2">
                        <div className="second-column-container">
                            <div className="ontactUsTitle">
                                CONTÁCTANOS
                            </div>
                            <div className="contactUsText">
                                ¿Requiere de soluciones tecnológicas para su negocio? Nosotros le cotizamos su proyecto.
                            </div>

                            <form onSubmit={sendEmail} id="form">
                                <div className="input-text-container">
                                    {/* requiredLabel */}
                                    <InputText name={"name"} id="name" value={inputList.name.value} onChange={(e) => { UpdateValue(e, "name", inputList, setInputList) }} requiredLabel placeholder={"Nombre"} className="input50"></InputText>
                                    <InputText name={"email"} id="email" value={inputList.email.value} onChange={(e) => { UpdateValue(e, "email", inputList, setInputList) }} requiredLabel placeholder={"E-mail"} className="input50"></InputText>
                                </div>
                                <div className="input-text-container-ONE">
                                    <InputText  name={"phone"} id="phone" value={inputList.phone.value} requiredLabel onChange={(e) => { UpdateValue(e, "phone", inputList, setInputList) }} placeholder={"Número Telefónico"}></InputText>
                                </div>
                                <div className="input-text-container-ONE">
                                    <TextArea2 name="message" id="message" value={inputList.message.value} requiredLabel onChange={(e) => { UpdateValue(e, "message", inputList, setInputList) }} placeholder={"Deja un comentario"}></TextArea2>
                                </div>
                                <div className="input-text-container-ONE">
                                    <Toast ref={toast} />
                                    <button className="btn-gradient" type='submit'>ENVIAR</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TeamAndContactUs