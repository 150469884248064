import React, { lazy, Suspense } from 'react'
import GoalsSkeleton from './GoalsSkeleton'
const Goals = lazy(() => import("./Goals"));

const GoalPage = () => {
    return (
        <Suspense fallback={<GoalsSkeleton></GoalsSkeleton>}>
            <Goals/>
        </Suspense>
    )
}

export default GoalPage