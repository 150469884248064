import React, { lazy, Suspense } from "react";
import ServicesSkeleton from "./ServicesSkeleton";
const ServicePage = lazy(()=>import("./ServicePage"));


const Services = ({id}) => {
    return (
        <>
            <Suspense fallback={<ServicesSkeleton></ServicesSkeleton>}>
                <ServicePage id={id}></ServicePage>
            </Suspense>
        </>
    )
}
export default Services