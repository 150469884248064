//styles
import "./Home.scss"

//images
import image1 from "../../../img/grafico_banner_01.png"

//components
import Header from "../../molecules/Header/Header"
import HeaderMobile from "../../molecules/HeaderMobile/HeaderMobile"
import { Skeleton } from "primereact/skeleton"

const HomeSkeleton = () => {

    const screen = window.screen.width  // horizontal screen resolution 

    return (
        <div className='Home' id="home">
            {/* HeaderMobile will apply for tablets and mobile */}
            {screen > 768 ? <Header skeleton/> : <HeaderMobile skeleton/>}
            <div className="mainContainer">

                <div className="contextMain">
                    <div className="text" style={{ maxWidth: '600px', width: '100%' }}>
                        <Skeleton width="100%" height="40px" />
                        <Skeleton width="50%" height="40px" />
                        <Skeleton width="100%" height="70px" />

                        {/* This will apply for resolutions greater than 480px */}
                        {screen > 768 &&
                            <>
                                <Skeleton width="100%" height="150px" />
                                <Skeleton width="50%" height="50px" borderRadius="50px" />
                            </>
                        }
                    </div>
                    <div className="image" style={{ marginTop: '100px' }}>
                        <Skeleton width="80%" height="300px" />
                    </div>

                    {/* This will apply for resolutions less than or equal to 480px */}
                    {screen <= 768 &&
                        <div style={{maxWidth:'600px', width:'100%', marginTop:'50px', display:'flex', flexDirection:'column', gap:'20px'}}>
                            <>
                                <Skeleton width="100%" height="150px" />
                                <Skeleton width="100%" height="50px" borderRadius="50px" />
                            </>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default HomeSkeleton