//styles
import "./Goals.scss"

//images
import image from "../../../img/grafico_code_builders.png"

//react
import { Skeleton } from "primereact/skeleton"

const GoalsSkeleton = () => {

    return (
        <div className='Goals' id="us">
            <div className="mainContainer">
                <div className="image" style={{ maxWidth: '500px', width: '100%', height: '100%', maxHeight: '400px' }}>
                    <Skeleton width="100%" height="100%" />
                </div>
                <div style={{maxWidth:'600px', width:'100%', gap:'30px', display:'flex', flexDirection:'column'}}>
                    <Skeleton width="280px" height="50px" />
                    <Skeleton width="100%" height="90px" />
                    <Skeleton width="100%" height="70px" />
                    <Skeleton width="100%" height="40px" />
                    
                    <Skeleton width="50%" height="50px" borderRadius="50px" />
                </div>
            </div>
        </div>
    )
}

export default GoalsSkeleton